@import 'core';

:root{
    --bs-link-color: #{$color__link};
    --bs-link-hover-color: #{$color__font};
    --bs-border-color: var(--border-color, #{$color__gray});
    --bs-border-radius: var(--border-radius, 4px);
    --bs-border-width: var(--border-width, 1px);
    --bs-border-style: var(--border-style, solid);
    --bs-light-rgb: 242,244,247; //rgb($color__gray--light)
    --bs-border-radius-sm: var(--bs-border-radius);
}

a{
    &.with-icon{
        display: inline-flex;
        align-items: flex-start;

        .icon--fluid{
            > img,
            > svg{
                transform: translateY(1px);
            }
        }
    }
}

.button {
    &--ticketing {
        position: relative;
        padding: 16px 52px 16px 16px;

        overflow: hidden;
        border: none;
        box-shadow: none;

        background-color: $color__primary;
        color: $color__white;

        .icon {
            position: absolute;
            top: 50%;
            right: 6px;
            transform: translateY(-50%);

            img {
                filter: $filter__white;
            }
        }

        .separator {
            position: absolute;
            right: 32px;
            top: 0;
            height: 100%;
            width: 0;
            border-left: dashed 2px $color__white;

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;

                transform: translate(50%, calc(-100% + 6px));

                height: 27px;
                width: 27px;
                border-radius: 50%;

                background-color: $color__white;
            }

            &:after {
                top: auto;
                bottom: 0;

                transform: translate(50%, calc(100% - 6px));
            }
        }

    }
}

[data-title] {
    position: relative;
    z-index: 2;

    &:hover {
        cursor: help;

        &:before {
            content: attr(data-title);
            position: absolute;
            bottom: calc(100% + 8px);
            left: 50%;
            padding: 8px;
            font-family: var(--bs-body-font-family);
            font-size: 16px;
            line-height: 1;
            white-space: nowrap;
            color: #fff;
            background-color: #000;
            border-radius: 4px;
            pointer-events: none;
            transform: translateX(-50%);
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            border: 8px solid transparent;
            border-top-color: currentColor;
            border-bottom-width: 0;
            pointer-events: none;
            transform: translateX(-50%);
        }
    }
}

.bg-lighter,
.bg-light{
    background-color: $color__gray--light!important;
}

.fs-allowed {
    position: relative;

    > img{

        + .button--fullscreen {
            position: absolute;
            padding: 4px 8px;
            right: 32px;
            bottom: 16px;

            background-color: $color__white;

            @include hover-focus-visible(){
                background-color: $color__primary;
                img {
                    filter: $filter__white;
                }
            }
        }
    }
}

.card{
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1.5rem;
    --bs-card-cap-bg: #{$color__gray--light};
    --bs-card-border-color: var(--bs-border-color);
    transition: border-color .15s ease-in-out;
    color: $color__font;

    &:hover,
    &:focus-within{
        --bs-card-border-color: #{$color__font};

        .dgf-card-icon--arrow{
            margin-right: -4px;
        }

        .dgf-card-icon--download{
            span{
                text-decoration: underline;
            }
        }
    }

    a{
        &.standalone{
            color: $color__font;
            font-weight: $font__weight--bold;

            &:hover,
            &:focus {
                text-decoration: underline;
                text-decoration-color: currentColor;
            }
        }
    }

    // card in button-style
    &--button{
        overflow: hidden;

        > .row{
            flex-wrap: nowrap;
            align-items: center;
            min-height: 96px;
        }

        .card-text{
            margin-top: 0!important;

            line-height: 1.25;
            font-size: .85em;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .card-body{
            padding-right: calc(var(--bs-card-spacer-x)*1.5);

            @include mq(sm){
                padding-right: calc(var(--bs-card-spacer-x)*2);
            }
        }

        .dgf-card-icon--arrow,
        .dgf-card-icon--download{
            position: absolute;
            top: 50%;
            transform: translate(16px, -50%);
            right: var(--bs-card-spacer-x);

            @include mq(sm){
                transform: translate(8px, -50%);
            }
        }

        .dgf-card-icon--download{

            span {
                color: $color__primary;
                font-size: 1rem;
                display: none;

                @include mq(sm){
                    display: inline;
                }
            }

            img {
                filter: var(--filter-primary);
                margin-left: .5rem;
            }
        }

        .dgf-card-start-col{

            &.dgf-card-icon,
            &.dgf-card-image{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0;

                flex-basis: 98px;
                padding: var(--bs-card-spacer-y) 1rem;

                @include mq(md){
                    flex-basis: 114px;
                    padding: var(--bs-card-spacer-y) 1.5rem;
                    //padding-left: 1.5rem;
                    //padding-right: 1.5rem;
                }

                + .col{
                    padding-left: 0;
                    max-width: calc(100% - 108px);

                    @include mq(md){
                        max-width: calc(100% - 124px);
                    }

                    .card-body{
                        padding-left: 0;
                    }
                }
            }

            &.dgf-card-icon{
                >.dgf-icon{
                    height: 2rem;
                    width: 2rem;
                }
            }

            &.dgf-card-image{

                > img{
                    width: 66px;
                    height: 66px;
                    max-width: none;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        &.card--download{
            .card-body{
                padding-right: calc(var(--bs-card-spacer-x)*3);

                @include media-breakpoint-up(sm){
                    padding-right: calc(var(--bs-card-spacer-x)*6);
                }
            }
        }
    }

    // specific
    &--socialmedia,
    &--external-sites{

        @include mq(md, max){
            width: 63px;
            height: 63px;
            overflow: hidden;
        }

        .row{
            flex-direction: column;
        }

        .dgf-card-image{
            flex-basis: auto;

            @include mq(md, max){
                width: 61px;
                height: 61px;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            > img{
                padding-top: 1rem;
                padding-left: var(--bs-card-spacer-x);

                width: 2.75rem;
                height: 2.75rem;
                box-sizing: content-box;

                @include mq(md, max){
                    padding: 0;
                    width: 45px;
                    height: 45px;
                    object-fit: contain;
                }
            }
        }

        .card-body{
            padding: 1rem calc(var(--bs-card-spacer-x)*1.5) calc(var(--bs-card-spacer-y)*.75);

            @include mq(md, max) {
                //visibility: hidden;
                height: 0;
                padding: 0;
                overflow: hidden;
                opacity: 0;
            }

            .dgf-card-icon--arrow {
                right: calc(var(--bs-card-spacer-x) * .5);
            }
        }
    }

    /*&-img-top{
        .dgf-list &{
            border-bottom: 1px solid $color__gray;
        }
    }*/

    &-body{
        .dgf-list &{
            display: flex;
            flex-direction: column;
            flex: 1;

            padding-bottom: calc(var(--bs-card-spacer-y) * 2.5) ;
        }

        .dgf-card-icon--arrow,
        .dgf-card-icon--download{
            position: absolute;
            right: var(--bs-card-spacer-x);
            bottom: 14px;

            display: flex;
            justify-content: flex-end;
            align-items: center;
            transition: margin-right .15s ease-in-out;
        }
    }

    &-text{
        a:not(.stretched-link){
            position: relative;
            z-index: 1;
        }

        .dgf-list &{
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        &__info{
            + .card-text__contact{
                padding-top: 24px;

                @include mq(sm){
                    padding-top: 48px;
                }
            }
        }

        &__contact{
            margin-top: auto;
            display: flex;
            flex-direction: column;

            .icon--fluid{
                transform: translateY(0);
            }
        }

        &__icon{
            margin-top: 24px;
        }

        .d-flex > span.icon {
            flex: auto 0 0;
        }
    }
}

.dgf-row{
    gap: var(--grid-gutter-width, 2rem) 0;
}

/* dgf-icons */
.dgf-icon{
    display: flex;

    mask-position: center;
    mask-size: contain;
    background-color: currentColor;

    &.icon--black{
        background-color: $color__font;
    }
}

.dgf-card-image{
    &--empty{
        position: relative;
        padding-top: 66.666667%;
        height: 0;
        overflow: hidden;
        background-color: $color__gray--light;

        border-radius: var(--bs-card-border-radius) var(--bs-card-border-radius) 0 0;
        //border-bottom: 1px solid $color__gray;

        > img{
            position: absolute;
            top: 50%;
            left: 50%;
            filter: invert(60%) sepia(5%) saturate(47%) hue-rotate(22deg) brightness(98%) contrast(86%);
            transform: translate(-50%, -50%);
        }
    }

    .dgf-content-banner &{
        @include mq(md, max){
            > img{
                max-width: 100%;
                width: auto;
                height: auto;
            }
        }
    }
}

.comment{
    font-size: 0.8em;
}

.dgf-description-list {

    dt {
        > .accordion-button {
            padding: 1rem 0;
            border-bottom: 2px solid $color__font;

            > .d-flex {
                flex: 1;
            }
        }

        &:has(> .accordion-button) {
            + dd {
                margin-top: 0;
            }
        }
    }

    dd {
        margin-top: 0.25rem;
    }

    & + & {
        margin-top: 16px;

        @include mq(md){
            margin-top: 24px;
        }

        &.communication{
            margin-top: 32px;

            @include mq(md){
                margin-top: 48px;
            }
        }
    }

    &.dgf-description-list--horizontal{
        dd{
            margin-top: 0;
        }
    }

    &.structure__hierarchy-units {
        .parent {
            + .children {
                padding-left: 16px;

                > .group {

                    > .parent {
                        position: relative;

                        &:before {
                            content: "";
                            height: 3px;
                            width: 16px;
                            position: absolute;
                            left: -16px;
                            top: 50%;
                            transform: translateY(-50%);
                            background-color: $color__font;
                        }

                        &:after {
                            content: "";
                            height: calc(50% + 16px);
                            width: 3px;
                            position: absolute;
                            left: -16px;
                            top: -16px;
                            background-color: $color__font;
                        }
                    }

                    &:has(+ .group) {
                        > .parent {
                            &:after {
                                height: calc(100% + 16px);
                            }
                        }
                    }
                }
            }
        }
    }
}

.icon {
    &__image{
        filter: var(--filter-font);
    }

    &--2xs {
        > img,
        > svg {
            height: .75rem;
            width: .75rem
        }
    }

    &--xs {
        > img,
        > svg{
            height: 1rem;
            width: 1rem
        }
    }

    &--s {
        > img,
        > svg {
            height: 1.25rem;
            width: 1.25rem
        }
    }

    &--m > {
        img,
        svg {
            height: 1.5rem;
            width: 1.5rem
        }
    }

    &--l {
        > img,
        > svg{
            height: 2rem;
            width: 2rem
        }
    }

    &--xl {
        > img,
        > svg{
            height: 2.5rem;
            width: 2.5rem
        }
    }

    &--2xl {
        > img,
        > svg {
            height: 3rem;
            width: 3rem
        }
    }

    &--fluid {
        > img,
        > svg{
            height: 1.4em;
            width: 1.4em;
        }

        .dgf-heading & {
            height: 1em;
            width: 1em;
        }
    }
}

.leaflet-container{
    z-index: 0;
}

.modal-open{
    .page__header{
        z-index:-1;
    }
}

.card{
    &--info{
        &-popup{
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            background-color: var(--color-font);
            display: none;
            z-index: 3;
            color: #fff;
            padding: 24px;
            text-align: left;
            width: 520px;

            @media (max-width: 576px) {
                width: 100%;
            }

            &::after{
                content: "";
                position: absolute;
                height: 0;
                width: 0;
                left: 32px;
                bottom: -30px;
                border-width: 16px;
                border-color: var(--color-font) rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0);
                border-style: solid;
            }

            p{
                text-align: left;
            }
        }

    }

    @include hover-focus-visible(){
        .card-header{
            &__info{
                .card{
                    &--icon-wrapper{
                        background-color: var(--color-font);
                    }
                }
            }
        }
    }

    .card-header{
        &__info{
            .card{
                &--icon-wrapper{
                    height: 24px;
                    width: 24px;
                    position: absolute;
                    right: 8px;
                    top: 8px;
                    background-color: var(--color-primary);
                    border-radius: 50%;

                    img{
                        filter: invert(100%);
                    }
                }

                &--icon{
                    display: block;
                    background-image: url('/bundles/disgoveryaddress/img/custom-icons/info.svg');
                    height: 24px;
                    width: 24px;
                    filter: invert(100%);
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }

        &:not(.hidden-popup){
            .card{
                &--info-popup{
                    display: block;
                }
                &--icon{
                    background-image: url('/bundles/hitcomfrontend/img/material-design-icons/outlined/close.svg');
                    background-size: 16px;
                }
            }
        }
    }
}
