.m-1-5 {
    margin: .375rem!important
}

.m-2-5 {
    margin: .75rem!important
}

.m-3-5 {
    margin: 1.25rem!important
}

.m-4-25 {
    margin: 1.75rem!important
}

.m-4-5 {
    margin: 2rem!important
}

.m-4-75 {
    margin: 2.5rem!important
}

.mx-1-5 {
    margin-left: .375rem!important;
    margin-right: .375rem!important
}

.mx-2-5 {
    margin-left: .75rem!important;
    margin-right: .75rem!important
}

.mx-3-5 {
    margin-left: 1.25rem!important;
    margin-right: 1.25rem!important
}

.mx-4-25 {
    margin-left: 1.75rem!important;
    margin-right: 1.75rem!important
}

.mx-4-5 {
    margin-left: 2rem!important;
    margin-right: 2rem!important
}

.mx-4-75 {
    margin-left: 2.5rem!important;
    margin-right: 2.5rem!important
}

.my-1-5 {
    margin-bottom: .375rem!important;
    margin-top: .375rem!important
}

.my-2-5 {
    margin-bottom: .75rem!important;
    margin-top: .75rem!important
}

.my-3-5 {
    margin-bottom: 1.25rem!important;
    margin-top: 1.25rem!important
}

.my-4-25 {
    margin-bottom: 1.75rem!important;
    margin-top: 1.75rem!important
}

.my-4-5 {
    margin-bottom: 2rem!important;
    margin-top: 2rem!important
}

.my-4-75 {
    margin-bottom: 2.5rem!important;
    margin-top: 2.5rem!important
}

.mt-1-5 {
    margin-top: .375rem!important
}

.mt-2-5 {
    margin-top: .75rem!important
}

.mt-3-5 {
    margin-top: 1.25rem!important
}

.mt-4-25 {
    margin-top: 1.75rem!important
}

.mt-4-5 {
    margin-top: 2rem!important
}

.mt-4-75 {
    margin-top: 2.5rem!important
}

.me-1-5 {
    margin-right: .375rem!important
}

.me-2-5 {
    margin-right: .75rem!important
}

.me-3-5 {
    margin-right: 1.25rem!important
}

.me-4-25 {
    margin-right: 1.75rem!important
}

.me-4-5 {
    margin-right: 2rem!important
}

.me-4-75 {
    margin-right: 2.5rem!important
}

.mb-1-5 {
    margin-bottom: .375rem!important
}

.mb-2-5 {
    margin-bottom: .75rem!important
}

.mb-3-5 {
    margin-bottom: 1.25rem!important
}

.mb-4-25 {
    margin-bottom: 1.75rem!important
}

.mb-4-5 {
    margin-bottom: 2rem!important
}

.mb-4-75 {
    margin-bottom: 2.5rem!important
}

.ms-1-5 {
    margin-left: .375rem!important
}

.ms-2-5 {
    margin-left: .75rem!important
}

.ms-3-5 {
    margin-left: 1.25rem!important
}

.ms-4-25 {
    margin-left: 1.75rem!important
}

.ms-4-5 {
    margin-left: 2rem!important
}

.ms-4-75 {
    margin-left: 2.5rem!important
}

.m-n1 {
    margin: -.25rem!important
}

.m-n2 {
    margin: -.5rem!important
}

.m-n3 {
    margin: -1rem!important
}

.m-n4 {
    margin: -1.5rem!important
}

.m-n5 {
    margin: -3rem!important
}

.mx-n1 {
    margin-left: -.25rem!important;
    margin-right: -.25rem!important
}

.mx-n2 {
    margin-left: -.5rem!important;
    margin-right: -.5rem!important
}

.mx-n3 {
    margin-left: -1rem!important;
    margin-right: -1rem!important
}

.mx-n4 {
    margin-left: -1.5rem!important;
    margin-right: -1.5rem!important
}

.mx-n5 {
    margin-left: -3rem!important;
    margin-right: -3rem!important
}

.my-n1 {
    margin-bottom: -.25rem!important;
    margin-top: -.25rem!important
}

.my-n2 {
    margin-bottom: -.5rem!important;
    margin-top: -.5rem!important
}

.my-n3 {
    margin-bottom: -1rem!important;
    margin-top: -1rem!important
}

.my-n4 {
    margin-bottom: -1.5rem!important;
    margin-top: -1.5rem!important
}

.my-n5 {
    margin-bottom: -3rem!important;
    margin-top: -3rem!important
}

.mt-n1 {
    margin-top: -.25rem!important
}

.mt-n2 {
    margin-top: -.5rem!important
}

.mt-n3 {
    margin-top: -1rem!important
}

.mt-n4 {
    margin-top: -1.5rem!important
}

.mt-n5 {
    margin-top: -3rem!important
}

.me-n1 {
    margin-right: -.25rem!important
}

.me-n2 {
    margin-right: -.5rem!important
}

.me-n3 {
    margin-right: -1rem!important
}

.me-n4 {
    margin-right: -1.5rem!important
}

.me-n5 {
    margin-right: -3rem!important
}

.mb-n1 {
    margin-bottom: -.25rem!important
}

.mb-n2 {
    margin-bottom: -.5rem!important
}

.mb-n3 {
    margin-bottom: -1rem!important
}

.mb-n4 {
    margin-bottom: -1.5rem!important
}

.mb-n5 {
    margin-bottom: -3rem!important
}

.ms-n1 {
    margin-left: -.25rem!important
}

.ms-n2 {
    margin-left: -.5rem!important
}

.ms-n3 {
    margin-left: -1rem!important
}

.ms-n4 {
    margin-left: -1.5rem!important
}

.ms-n5 {
    margin-left: -3rem!important
}

.p-1-5 {
    padding: .375rem!important
}

.p-2-5 {
    padding: .75rem!important
}

.p-3-5 {
    padding: 1.25rem!important
}

.p-4-25 {
    padding: 1.75rem!important
}

.p-4-5 {
    padding: 2rem!important
}

.p-4-75 {
    padding: 2.5rem!important
}

.px-1-5 {
    padding-left: .375rem!important;
    padding-right: .375rem!important
}

.px-2-5 {
    padding-left: .75rem!important;
    padding-right: .75rem!important
}

.px-3-5 {
    padding-left: 1.25rem!important;
    padding-right: 1.25rem!important
}

.px-4-25 {
    padding-left: 1.75rem!important;
    padding-right: 1.75rem!important
}

.px-4-5 {
    padding-left: 2rem!important;
    padding-right: 2rem!important
}

.px-4-75 {
    padding-left: 2.5rem!important;
    padding-right: 2.5rem!important
}

.py-1-5 {
    padding-bottom: .375rem!important;
    padding-top: .375rem!important
}

.py-2-5 {
    padding-bottom: .75rem!important;
    padding-top: .75rem!important
}

.py-3-5 {
    padding-bottom: 1.25rem!important;
    padding-top: 1.25rem!important
}

.py-4-25 {
    padding-bottom: 1.75rem!important;
    padding-top: 1.75rem!important
}

.py-4-5 {
    padding-bottom: 2rem!important;
    padding-top: 2rem!important
}

.py-4-75 {
    padding-bottom: 2.5rem!important;
    padding-top: 2.5rem!important
}

.pt-1-5 {
    padding-top: .375rem!important
}

.pt-2-5 {
    padding-top: .75rem!important
}

.pt-3-5 {
    padding-top: 1.25rem!important
}

.pt-4-25 {
    padding-top: 1.75rem!important
}

.pt-4-5 {
    padding-top: 2rem!important
}

.pt-4-75 {
    padding-top: 2.5rem!important
}

.pe-1-5 {
    padding-right: .375rem!important
}

.pe-2-5 {
    padding-right: .75rem!important
}

.pe-3-5 {
    padding-right: 1.25rem!important
}

.pe-4-25 {
    padding-right: 1.75rem!important
}

.pe-4-5 {
    padding-right: 2rem!important
}

.pe-4-75 {
    padding-right: 2.5rem!important
}

.pb-1-5 {
    padding-bottom: .375rem!important
}

.pb-2-5 {
    padding-bottom: .75rem!important
}

.pb-3-5 {
    padding-bottom: 1.25rem!important
}

.pb-4-25 {
    padding-bottom: 1.75rem!important
}

.pb-4-5 {
    padding-bottom: 2rem!important
}

.pb-4-75 {
    padding-bottom: 2.5rem!important
}

.ps-1-5 {
    padding-left: .375rem!important
}

.ps-2-5 {
    padding-left: .75rem!important
}

.ps-3-5 {
    padding-left: 1.25rem!important
}

.ps-4-25 {
    padding-left: 1.75rem!important
}

.ps-4-5 {
    padding-left: 2rem!important
}

.ps-4-75 {
    padding-left: 2.5rem!important
}

.gap-1-5 {
    gap: .375rem!important
}

.gap-2-5 {
    gap: .75rem!important
}

.gap-3-5 {
    gap: 1.25rem!important
}

.gap-4-25 {
    gap: 1.75rem!important
}

.gap-4-5 {
    gap: 2rem!important
}

.gap-4-75 {
    gap: 2.5rem!important
}


@media (min-width: 992px) {
    .dgf-sidebar .h2,.dgf-sidebar h2 {
        font-size:1.5rem
    }
}

.dgf-card-start-col {
    -ms-flex-preferred-size: 160px;
    flex-basis: 160px;
    padding: 0
}

.dgf-card-start-col.dgf-size-small {
    -ms-flex-preferred-size: 120px;
    flex-basis: 120px
}

.dgf-card-start-col.dgf-size-large {
    -ms-flex-preferred-size: 255px;
    flex-basis: 255px
}

.dgf-card-start-col.dgf-size-extra-large {
    -ms-flex-preferred-size: 350px;
    flex-basis: 350px
}

.row .dgf-card-start-col {
    margin-left: calc(var(--bs-gutter-x)*.5);
    margin-right: calc(var(--bs-gutter-x)*.5)
}

@media (max-width: 991.98px) {
    .dgf-content-banner .dgf-card-start-col {
        margin:0 auto;
        padding: 0 calc(var(--bs-gutter-x)*.5)
    }
}

a:not(.text-underline-hover):not(.standalone):hover {
    -webkit-text-decoration-color: currentColor;
    text-decoration-color: currentColor
}

a.standalone,a.text-underline-hover {
    -webkit-text-decoration-color: transparent;
    text-decoration-color: transparent
}

a.standalone:hover,a.text-underline-hover:hover {
    -webkit-text-decoration-color: currentColor;
    text-decoration-color: currentColor
}

svg {
    fill: currentColor
}

@media (max-width: 767.98px) {
    .n-mx-container {
        margin-left:-1.5rem;
        margin-right: -1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }
}

.bg-lighter {
    background-color: #f8f9fa
}

.bg-gray-200 {
    background-color: #e9ecef
}

.bg-gray-400 {
    background-color: #ced4da
}

.border-gray-300 {
    border-color: #dee2e6
}

.grid-3-9 {
    -ms-grid-columns: 25% 75%;
    grid-gap: .75rem;
    grid-template-columns: 25% 75%
}

dd {
    margin-bottom: 0
}

dd+dd,dd>div+div {
    margin-top: .5rem
}

@media (max-width: 767.98px) {
    dd+dt {
        margin-top:.75rem
    }
}

.dgf-header .container {
    position: relative
}

.dgf-header--neutral .dgf-header__navbar,.dgf-header--neutral .dgf-header__project {
    background: #495057
}

.dgf-header:not(.dgf-header--neutral) .dgf-header__project {
    padding-top: 1rem
}

.dgf-header .navbar-brand img {
    width: 100px
}

.dgf-header .notification {
    padding-right: 0;
    position: relative
}

.dgf-header .notification span {
    border-radius: 10px;
    height: 18px;
    line-height: .6rem;
    padding-left: 5px;
    padding-right: 5px;
    -webkit-transform: translate(-11px,5px);
    transform: translate(-11px,5px);
    z-index: 1
}

.dgf-header__project .dgf-header__site-name {
    color: #fff;
    display: inline-block;
    font-size: 1.3rem;
    font-weight: 300;
    margin-bottom: 0;
    max-width: 80%;
    vertical-align: middle
}

@media only screen and (max-width: 400px) {
    .dgf-header--neutral .dgf-header__project-logo {
        display:none
    }
}

@media (max-width: 575.98px) {
    .dgf-header .navbar:first-child .nav-link {
        padding:.5rem .4rem
    }
}

@media (min-width: 768px) {
    .dgf-header--ec .navbar-brand img,.ec__header .navbar-brand img {
        width:180px
    }
}

@media (max-width: 991.98px) {
    .dgf-header__site-name {
        margin-left:5rem
    }

    .dgf-header__top-navbar .navbar-collapse {
        background: #fff;
        border: 1px solid #dee2e6;
        border-radius: 4px;
        position: absolute;
        right: .75rem;
        top: 100%;
        z-index: 1050
    }

    .dgf-header__top-navbar .navbar-collapse .nav {
        -ms-flex-align: start;
        align-items: flex-start;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 16rem;
        padding: 1rem
    }

    .dgf-header__top-navbar .navbar-collapse .nav li {
        width: 100%
    }

    .dgf-header__project {
        min-height: 3rem;
        padding-top: 1rem
    }

    .dgf-header nav+nav {
        min-height: 3.5rem
    }

    .dgf-header nav+nav .navbar-toggler {
        position: static
    }

    .dgf-header__navbar .navbar-collapse,.dgf-header__navbar .navbar-collapse .nav-item.dropdown {
        max-width: 100%
    }

    .dgf-header__navbar .navbar-collapse .nav-item.dropdown a {
        white-space: normal
    }

    .dgf-header__navbar .navbar-collapse .nav-item.dropdown .dropdown-menu {
        max-width: 100%
    }
}

@media (min-width: 992px) {
    .dgf-header--ec .dgf-header__project.light,.dgf-header__project.light,.ec__header .dgf-header__project.light {
        background:#fff
    }

    .dgf-header__site-name {
        font-weight: 500;
        margin-left: 1.5rem
    }

    .dgf-header__project.light .dgf-header__site-name {
        color: #000
    }

    .dgf-header .navbar-toggler {
        bottom: 0;
        position: relative;
        right: 0
    }

    .dgf-header .navbar-brand img {
        width: auto
    }

    .dgf-header__navbar .navbar-collapse {
        padding-bottom: 0
    }

    .dgf-header__navbar .nav-link.active {
        margin-bottom: -.5rem;
        margin-top: -.5rem;
        padding-bottom: 1rem;
        padding-top: 1rem
    }

    .dgf-header__navbar .dropdown-menu {
        z-index: 1050
    }

    .dgf-header:not(.dgf-header--neutral) .dgf-header__project {
        padding-bottom: 1rem
    }

    .dgf-header--ec .navbar-brand img,.ec__header .navbar-brand img {
        width: 285px
    }
}

.dgf-banner {
    position: relative
}

.dgf-banner p {
    font-size: 1.125rem;
    font-weight: 500
}

.dgf-banner.shade {
    color: #212529
}

.dgf-banner.bg-primary .dgf-banner__content {
    border-color: #fff!important
}

.dgf-banner.hero p {
    font-size: 1rem
}

.dgf-banner.hero .dgf-banner__content {
    padding: 3rem 2rem
}

.dgf-banner__content {
    border-color: #253ebe;
    padding: 2.5rem 2rem
}

.dgf-banner__image {
    background-position: 50%;
    background-size: cover;
    overflow: hidden;
    width: 100%
}

.dgf-banner__image:before {
    content: "";
    display: block;
    padding-top: 20%;
    width: 100%
}

.hero .dgf-banner__image:before {
    padding-top: 25%
}

@media (max-width: 767.98px) {
    .container .dgf-content-banner .container {
        padding:0
    }
}

@media (min-width: 768px) {
    .dgf-banner {
        border-radius:4px;
        overflow: hidden
    }

    .dgf-banner.overlay .dgf-banner__content {
        background: #fff;
        border-bottom: 4px solid #253ebe
    }

    .dgf-banner.shade {
        color: #fff
    }

    .dgf-banner.shade .dgf-banner__image:before {
        background-color: rgba(0,0,0,.5);
        content: "";
        display: block;
        height: 100%;
        width: 100%
    }

    .dgf-banner.hero p {
        font-size: 1.125rem
    }

    .dgf-banner.hero .dgf-banner__content {
        margin: 5.25rem auto;
        padding: 2rem
    }

    .dgf-banner:not(.text-center):not(.shade) .dgf-banner__content {
        border-bottom: 0;
        border-left: 4px solid transparent;
        margin-left: 2.5rem
    }

    /*.dgf-content-banner,*/
    .dgf-banner.full-width{
        border-radius: 0;
        left: 50%;
        margin-left: -50vw;
        position: relative;
        width: 100vw
    }

    .dgf-banner__content {
        border-radius: 4px;
        display: inline-block;
        margin: 2.75rem auto;
        min-width: 540px;
        padding: 2rem;
        position: relative;
        z-index: 1
    }

    .dgf-banner__image {
        background-size: cover;
        height: 100%;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 100%
    }

    .container .dgf-banner:not(.text-center):not(.shade) .dgf-banner__content {
        margin-left: 0
    }

    .container .dgf-content-banner .card {
        margin-left: -1.5rem;
        margin-right: -1.5rem
    }

    .tab-pane .dgf-content-banner .card {
        margin-left: 0;
        margin-right: 0
    }
}

@media (min-width: 992px) {
    .dgf-banner {
        -ms-flex-align:center;
        -ms-flex-pack: center;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        justify-content: center;
        min-height: 300px
    }

    .dgf-banner .container {
        position: absolute
    }

    .dgf-banner:before {
        content: "";
        display: block;
        padding-top: 20%;
        width: 100%
    }

    .dgf-banner.hero {
        min-height: 380px
    }

    .dgf-banner.hero:before {
        padding-top: 25%
    }
}